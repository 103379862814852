import React, { useEffect, useState } from 'react'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
// import './CheckoutForm.css';  // Ensure you have your CSS for styling
import config from './config/config'
import { fetchPaymentIntent } from '../actions/axioss/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { GetUserCookieToken, userRegister } from '../actions/axioss/user.axios';
import { connectWallet } from './hooks/useWallet';
import { GetNftCookieToken } from '../actions/axioss/nft.axios';

const CheckoutForm = (props) => {
const [userData , SetUserData] = useState({})
  const { payload , token} = useSelector(
    (state) => state.LoginReducer.User
  );
  const push = useNavigate()
  const dispatch = useDispatch();
  console.log("propspropspropsprops", payload)
    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
      SetUserData(payload)
    },[payload])

const [btndisable , setbtndisable] = useState(false)
    const handlePaymentSubmission = async (event) => {
      event.preventDefault();
      setbtndisable(true);
      const id = toast.loading('payment in progress');
      try {   
        let dat = {
            walletAddress: userData?.WalletAddress, 
            planId: props?.data._id
        };
        const clientSecret = await fetchPaymentIntent(dat);
        const cardElement = elements.getElement(CardElement);

        const result = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: cardElement,
            },
        });
        if (result.error) {
            console.error('Payment failed:', result.error);
            toast.error('Payment failed');

        } else {
            if (result.paymentIntent.status === 'succeeded') {
                console.log('Payment successful!');
                toast.success('Payment successful');
                await againconnectWallet()
                setTimeout(() => {
                  push("/");
                }, 1000);
            }
        }
      } catch (error) {
        console.error('Payment failed:', error);
      }finally{
        toast.dismiss(id);
      setbtndisable(false);

      }
    };

    const againconnectWallet = async()=>{
      const type = localStorage.walletConnectType 
      var accountDetails = await connectWallet(type)
      var NewMethod = {
        Type: 'InitialConnect',
        WalletAddress: accountDetails.accountAddress,
        WalletType: type,
    };

      let Resp = await userRegister(NewMethod);
      console.log('sdagfsudyfayusfxx', Resp)
      if (Resp?.already && Resp?.data?.DisplayName != "") {
        if (Resp?.success == 'success') {
            dispatch({
                type: 'Register_Section',
                Register_Section: {
                    User: {
                        payload: Resp?.data,
                        token: Resp?.token ? Resp.token : token
                    }
                }
            })
            document.cookie = 'token' + "=" + Resp?.token + ";" + ";path=/";
            GetNftCookieToken();
            GetUserCookieToken();
            return Resp
        }
        else return Resp
    }

    }
  return (
    <>
      <div className="form-container">
            <form onSubmit={handlePaymentSubmission}>
                    <CardElement 
                    options={{
                        style: {
                          base: {
                            color: "#fff", 
                            fontSize: "16px", 
                            fontFamily: `"Fira Code", monospace`,
                            fontSmoothing: "antialiased",
                            "::placeholder": {
                              color: "#ccc", 
                            },
                          },
                          invalid: {
                            color: "red", 
                            iconColor: "red", 
                          },
                        },
                      }} 
                     className="card-element" /> 
               
            

            <div className='vert__paymentDualBtnsHolder mt-5'>
                <button type="submit" className="vert__greenOutBtn firaFont vert__paymentBtns" disabled={!stripe}>
                    Cancel
                </button>
                <button disabled={btndisable} type='submit' className="vert__bannerBtn firaFont vert__paymentBtns">Pay Now<img src={require('../assets/images/vertendi/roundCross.svg').default} className="ver__bannerArrow ml-3" /></button>
                </div>

                </form>
        </div>
    </>
  )
}

export default CheckoutForm
