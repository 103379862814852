import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { GetUserPlanApi } from '../../actions/axioss/api';

function ViewPlan(props) {
    const { payload } = useSelector(
        (state) => state.LoginReducer.User
      );
      const [userPlanData , SetUserPlan] = useState({})

    const { data  } = props 
    useEffect(()=>{
        fetchUSerPlan()
        console.log('dagsdigxxxxasgdiuag' , payload)
        // userPlanData(payload?.userPlanId)
      },[payload?.userPlanId?._id])
    const fetchUSerPlan = async() =>{
      console.log('zzzpayload' ,payload )
  if(payload?.userPlanId?._id){
    const res = await GetUserPlanApi(payload?.userPlanId._id)
    SetUserPlan(res?.data)
  }
       

      
    
      
    }


  return (
    <div>        <div className="inner-content profile">
<h4 className="title-dashboard crossfitFont vert__subpageTitle" data-aos="fade-right">Current PLan</h4>
<form className="form-edit-profile">
                    <div className="user-profile">
                        <div className="title firaFont vert__subSecTitle">Plan</div>
                 
                 {console.log('xasdasadsas' , userPlanData)}
                    </div>
                    <div className="user-profile">

                        <fieldset className="mb-4">
                            <h6 className="firaFont">Plan Name <span className="required"></span></h6>
                            <input id="EmailId" type="text" disabled value={userPlanData?.planId?.plan} placeholder="hernes@gmail.com" className="firaFont"   autoComplete="off" />
                        </fieldset>
                        <fieldset className="mb-4">
                            <h6 className="firaFont">Plan Type <span className="required"></span></h6>
                            <input id="EmailId" type="text"disabled value={userPlanData?.planId?.planType} placeholder="hernes@gmail.com" className="firaFont"   autoComplete="off" />
                        </fieldset>
                        {/* <fieldset className="mb-4">
                            <h6 className="firaFont">Plan CurrentPrice in USD <span className="required"></span></h6>
                            <input id="EmailId" type="text" disabled value={userPlanData?.planId?.price} placeholder="hernes@gmail.com" className="firaFont"   autoComplete="off" />
                        </fieldset>
                        <fieldset className="mb-4">
                            <h6 className="firaFont">Plan CrptoPrice in Matic <span className="required"></span></h6>
                            <input id="EmailId" type="text" disabled value={userPlanData?.planId?.cyptoPrice} placeholder="hernes@gmail.com" className="firaFont"   autoComplete="off" />
                        </fieldset> */}
                        <fieldset className="mb-4">
                            <h6 className="firaFont">description</h6>
                            <input id="Bio" type="text" disabled value={userPlanData?.planId?.description} placeholder="Write Something about yourself" className="firaFont"  maxLength={150} autoComplete="off" />
                        </fieldset>
                        <fieldset className="mb-4">
                            <h6 className="firaFont">Balance Download </h6>
{userPlanData?.downloadLimit}
/
{userPlanData?.planId?.downloadLimit}


                            {/* <input id="Bio" type="text" value={userPlanData?.planId.description} placeholder="Write Something about yourself" className="firaFont"  maxLength={150} autoComplete="off" /> */}
                        </fieldset>
                        <fieldset className="mb-4">
                            <h6 className="firaFont">Balance Mint </h6>
{userPlanData?.mintLimit}
/
{userPlanData?.planId?.mintLimit}


                        </fieldset>
                       
                    </div>
           
                </form>
        
        </div>
    </div>
  )
}

export default ViewPlan