import React, { useEffect, useRef, useState } from 'react'
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';



import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Autoplay } from 'swiper/modules';
import Card from "../Card";
import Doodles from '../../assets/images/vertendi/lotties/doodles.json'
import Lottie from "lottie-react";
import { Container, Row, Col } from 'react-bootstrap';
import NoData from "../seperatemodals/nodata";
import config from '../config/config'
const MediaPlayer = (props) => {  
  const [availNfts,setAvailNfts] = useState([])




  useEffect(() => {
    const interval = setInterval(() => {
      getCurrentSlideIndex();
     

    }, 10000); // Check every 2 seconds

    return () => clearInterval(interval); // Clean up interval on unmount
  }, [availNfts]);

  const audioRef = useRef()

    const [value, setValue] = useState(0);
    const [duration,setDuration] = useState(0)
    const [currentDuration,setCurrentDuration] = useState(0)
    const [play,setPlay] = useState(false)
    const [audiourl , Setaudiourl] = useState()

    const [shuffle,setShuffle] = useState(false)
    const [loop,setLoop] = useState(false)
    const playerRef = useRef()
    const [LikedTokenList, setLikedTokenList] = useState([]);
    const [loadingstate, setLoadingstate] = useState(true);
    const [playerBtn,setPlayerBtn] = useState(true)
    var LikeForwardRef = useRef();

    const goNext = () => {
      if (playerRef.current && playerRef.current.swiper) {
        playerRef.current.swiper.slideNext();
      }
    };
    const goPrev = () => {
      if (playerRef.current && playerRef.current.swiper) {
        playerRef.current.swiper.slidePrev();        
      }
    };

    function LikeList(data) {
      setLikedTokenList(data);
    }


    useEffect(() =>{
setAvailNfts(props?.musicnfts)
setLoadingstate(props?.loadingstate)

console.log(playerRef?.current?.swiper.activeIndex,"yyyyy");
    },[props.musicnfts])
   
    console.log(availNfts,"availeeeeeeee");

    console.log("duration",duration);
    

  const handleSliderChange = (newValue) => {
    setDuration(newValue);
  };

  const getCurrentSlideIndex = ()=>{
    console.log("playerRefcurrenteeeee33" , playerRef?.current?.swiper);
    // goNext();
    const index =  playerRef?.current?.swiper.activeIndex
      if(availNfts?.length > 0){
      
       console.log('dhasudas' , availNfts[index] , index)
          const product =  availNfts[index]
        if(`${config.IMG_URL}/nft/${product?.NFTCreator}/Compressed/NFT/${product?.CompressedFile}` !== audiourl){
        Setaudiourl(`${config.IMG_URL}/nft/${product?.NFTCreator}/Compressed/NFT/${product?.CompressedFile}`)
          
        }
      }
  }

  const handleLoadMetaData = () =>{
    if(audioRef.current){
      setDuration(audioRef.current.duration)
      setCurrentDuration(audioRef.current.currentTime)
    }
  }

  const handlePlayAudio = () => {
    setPlay(!play)
    if(!play){
      if(audioRef.current) audioRef.current.play();
      setPlay(!play)
    }
    else if(play){
      if(audioRef.current) audioRef.current.pause();
    }
    
  }

  console.log(audioRef.current?.currentTime.toFixed(0),duration.toFixed(0),"ttttttt");


  const handleIncreaseDuration = () =>{
    if(audioRef.current){
      const newTime = Math.min(audioRef.current.currentTime + 3)
      audioRef.current.currentTime = newTime;

      setCurrentDuration(newTime)
    }
  }

  const handleDecreaseDuration = () =>{
    if(audioRef.current){
      const newTime = Math.min(audioRef.current.currentTime - 3)
      audioRef.current.currentTime = newTime;

      setCurrentDuration(newTime)
    }
  }

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentDuration(audioRef.current.currentTime); // Update the current time
    }
    console.log('condcheck',audioRef.current.currentTime.toFixed(0) === duration.toFixed(0))
    if(!loop){
      if(audioRef.current.currentTime.toFixed(0) === duration.toFixed(0)) {setPlay(!play);goNext()}
    }
  };



  return (
    <>

<section className="ver__landingSection">
        <div className="rightDoodlesHolder">
          <Lottie animationData={Doodles} loop />
          </div>
          <div className="leftDoodlesHolder topCreatorLeftDoodles">
          <Lottie animationData={Doodles} loop />
          </div>
          <img src={require('../../assets/images/vertendi/leftBlurBall.png')} className="banner__leftBlurBall topCreatorsLeftBlurBall" />
          <img src={require('../../assets/images/vertendi/rightBlurBall.png')} className="banner__righttBlurBall topCreatorsrightBlurBall" />
          <Container className="custom__vertContainer">
            <h2 className="vert__greenBorderText crossfitFont text-center">Player</h2>
            <div className="vert__playerSwiperBtnHolder">
            <button className="vert__playerHandleBtnLeft" onClick={() => goPrev()}><img src={require('../../assets/images/vertendi/playLeft.svg').default} className="img-fluid" /></button>
    <button className="vert__playerHandleBtnRight" onClick={() => goNext()}><img src={require('../../assets/images/vertendi/play.svg').default} className="img-fluid" /></button>
            
            <Swiper navigation={true} ref={playerRef} modules={[Navigation , Autoplay]} className="mySwiper player__swiper mt-5"
        centeredSlides={true}
              speed={2000}
              autoplay={{
                delay: 10000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                400: {
                  slidesPerView: 1.2,
                  spaceBetween: 20,
                },
                450: {
                  slidesPerView: 1.4,
                  spaceBetween: 20,
                },
                550:{
                  slidesPerView: 1.6,
                  spaceBetween: 20,
                },
                600: {
                  slidesPerView: 1.8,
                  spaceBetween: 20,
                },
                650: {
                  slidesPerView: 1.9,
                  spaceBetween: 20,
                },
                700: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                800: {
                  slidesPerView: 2.2,
                  spaceBetween: 20,
                },
                900: {
                  slidesPerView: 2.5,
                  spaceBetween: 20,
                },
                1000: {
                  slidesPerView: 2.8,
                  spaceBetween: 20,
                },
                1100: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1200: {
                  slidesPerView: 3.2,
                  spaceBetween: 20,
                },
                1300: {
                  slidesPerView: 3.6,
                  spaceBetween: 20,
                },
                1400: {
                  slidesPerView: 3.8,
                  spaceBetween: 20,
                },
                1500: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
                1600: {
                  slidesPerView: 4.2,
                  spaceBetween: 20,
                },
                1700: {
                  slidesPerView: 4.6,
                  spaceBetween: 20,
                },
                1800: {
                  slidesPerView: 4.8,
                  spaceBetween: 20,
                },
                1900: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
              
              }}
              >
   {loadingstate == true ? (
                <>
                  <div className="text-centre">
                    <h3>Loading...</h3>
                    <div className="load-more">
                    </div>
                  </div>
                </>
              ) : 

              availNfts && availNfts.length > 0 ? (
                availNfts.map((item, index) => ( <>
         
{console.log("dsxxxxxxxxxxxxxxzzz",item )}
            <SwiperSlide>
              <Card
                playerBtn={playerBtn}
                product={item}
                index={index}
                LikeList={LikeList}
                LikedTokenList={LikedTokenList}
                setLikedTokenList={setLikedTokenList}
                LikeForwardRef={LikeForwardRef}
              />
              {console.log('itexxxm' , item , index)}
              </SwiperSlide>
      </>
                )
              )
              ) : (
                <NoData />
              )}

      </Swiper>
      </div>

{/* <Row className="justify-content-center mt-5">
  <Col lg={6}>
  <div className="vert__playerHolder">
  { musicnfts.length > 0 && <AudioPlayer className="firaFont" 
      loop
      showSkipControls
      showJumpControls
      showFilledProgress
      showDownloadProgress
    autoPlay
    src={audiourl}
    onPlay={e => console.log("onPlay")}
  /> }
  </div>
  </Col>
</Row> */}

<Row className="justify-content-center mt-5" >
  <Col xl={5} lg={6} md={7} sm={10}>
  <div className='eg__mediaPlayerBox'>
        <div className='eg__mediaPlayerTopBox d-flex justify-content-between align-items-center gap-4'>
           

            <button className='eg__playerTopBtns' onClick={() => setShuffle(!shuffle)}><img src={require('../../assets/images/vertendi/shuffle.svg').default} className={`${!shuffle && 'eg__playerBtnDisabled'} img-fluid`} />
            </button>

            <button className='eg__playerTopBtns' onClick={() => goPrev()}><img src={require('../../assets/images/vertendi/backward.svg').default} className='img-fluid' />
            </button>

            <button className='eg__playerTopBtns' onClick={() => handleDecreaseDuration()}><img src={require('../../assets/images/vertendi/prev.svg').default} className='img-fluid' />
            </button>

            <button className='eg__playerTopBtns' onClick={() => handlePlayAudio()}><img src={play ? require('../../assets/images/vertendi/pause.svg').default : require('../../assets/images/vertendi/playPause.svg').default} className='img-fluid' />
            </button>

            <button className='eg__playerTopBtns' onClick={() => handleIncreaseDuration()}><img src={require('../../assets/images/vertendi/next.svg').default} className='img-fluid' />
            </button>

            <button className='eg__playerTopBtns' onClick={() => goNext()}><img src={require('../../assets/images/vertendi/forward.svg').default} className='img-fluid' />
            </button>
            <button className='eg__playerTopBtns' onClick={() => setLoop(!loop)}>
            <img src={require('../../assets/images/vertendi/repeat.svg').default} className={`${!loop && 'eg__playerBtnDisabled'} img-fluid`} />
            </button>
        </div>

        <div className='eg__mediaPlayerProgerssBox mx-auto mt-4'>
        <audio controls src={audiourl} ref={audioRef} onLoadedMetadata={handleLoadMetaData} onTimeUpdate={handleTimeUpdate} loop={loop}
         className='d-none'
          />

        {console.log(audioRef?.current,"audiiiiiiii")}
        <div>
          {/* <div className='eg__mediaPlayerTrack'>
            <div className='eg__mediaPlayerCompleted' style={{width:`${duration * 10}%`}}>
            <div className='eg__mediaPlayerThumb' style={{left : `${duration * 10}%`}}></div>
            </div>
            <div className='eg__mediaPlayerBalance'></div>
          </div> */}
        <RangeSlider 
        rangeSlideDisabled={true}     
         onInput={()=>handleSliderChange(duration*10)}  
        min={0}                
        max={100}
        value={[0, currentDuration.toFixed(0) * 10]}
         className={`single_thumb mt-5`} />
    </div>
    <div className='eg__mediaPlayerDurationBox mt-4 d-flex justify-content-between'>
        <p className='m-0 firaFont eg__mediaPlayerDuration'>{`00:${currentDuration < 10 ? '0' : ''}${currentDuration.toFixed(0)}`}</p>
        <p className='m-0 firaFont eg__mediaPlayerDuration'>{`00:${duration.toFixed(0)}`}</p>
    </div>
        </div>
      </div>
  </Col>
</Row>


            </Container>
            </section>
     
    </>
  )
}

export default MediaPlayer
