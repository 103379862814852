import config from './config'

var net={}
if(config.env === "demo" || config.env === "local"){
    net ={

        43113 : {  // Avalanche_Testnet 
            
            chain:"AVAX",
            rpcUrl:"https://ava-testnet.public.blastapi.io/ext/bc/C/rpc",            
            networkVersion:'43113',
            chainId :'43113',
            currencySymbol:"MATIC",
            tokenSymbol:"CAKE",
            siteUrl:"https://testnet.snowtrace.io",
            tokenAddress:"0xEb4fFed6B324b5FdC4d72AD3dA75bf1Fb2bB004A".toLowerCase(),
            deadaddress : '0x000000000000000000000000000000000000dEaD'.toLowerCase(),
            tradeContract   :   '0x600A3fEed87E3a2f9EFe61eAd7F1dAe8eA60a59d'.toLowerCase(),
            singleContract      :  '0x9A9EBc3A48D9ddc54A2D6A3599642aBA4363E7e1'.toLowerCase(),
            multipleContract  :   '0x4958A36d8d71abd35D5434EF78023B3284D93e63'.toLowerCase(),
            adminAddrsss:"0x025c1667471685c323808647299e5dbf9d6adcc9".toLowerCase(),
            chainName : "FUJI"
    
        }
    }
}

if(config.env === "stage"){
    net ={

        43113 : {  // Avalanche_Testnet 
            
            chain:"AVAX",
            rpcUrl:"https://ava-testnet.public.blastapi.io/ext/bc/C/rpc",            
            networkVersion:'43113',
            chainId :'43113',
            currencySymbol:"MATIC",
            tokenSymbol:"CAKE",
            siteUrl:"https://testnet.snowtrace.io",
            tokenAddress:"0xEb4fFed6B324b5FdC4d72AD3dA75bf1Fb2bB004A".toLowerCase(),
            deadaddress : '0x000000000000000000000000000000000000dEaD'.toLowerCase(),
            tradeContract   :   '0x600A3fEed87E3a2f9EFe61eAd7F1dAe8eA60a59d'.toLowerCase(),
            singleContract      :  '0x9A9EBc3A48D9ddc54A2D6A3599642aBA4363E7e1'.toLowerCase(),
            multipleContract  :   '0x4958A36d8d71abd35D5434EF78023B3284D93e63'.toLowerCase(),
            adminAddrsss:"0x025c1667471685c323808647299e5dbf9d6adcc9".toLowerCase(),
            chainName : "FUJI"
    
        }
    }
}
if(config.env === "production"){
 net ={
    137 : {   // binance mainnet
    
        chain:"Polygon",
        rpcUrl:"https://lively-sparkling-sun.matic.quiknode.pro/a01b1989cb84596814e4523b79c649317b7d9ad0",
        networkVersion:'137',
        chainId :'137',
        currencySymbol:"MATIC",
        chainName : "Polygon",
        tokenSymbol:"USDT",
        tokenAddress:'0xc2132d05d31c914a87c6611c10748aeb04b58e8f'.toLowerCase(),
        singleContract :'0xbacc6bb993477fc08ee0678ed0e1e49e074be327'.toLowerCase(),
        multipleContract:'0x750ea898cb00d5f89d293369fce76e4bf6a3e4b1'.toLowerCase(),
        tradeContract :"0xe22d6fc8e868b716ce98df7a7afc6459b2a1a112".toLowerCase(),
        adminAddress:"0x0fb556b7a5df171d324d8cb4e42d1ce0caab0c9f".toLowerCase()

    },


}
}

export const network = net