import React from "react";
import { Link } from "react-router-dom";
import User from "../assets/images/banner.png";
import action_config from './config/config'
import NoData from "./seperatemodals/nodata";
import author from '../assets/images/Artist1.png';
import Dropdown from 'react-bootstrap/Dropdown';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { FollowUnFollowFunc } from "../actions/axioss/user.axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import config from '../views/config/config'
export default function Following({DisplayName, datas, type, user }) {
  const { payload, isAdmin } = useSelector((state) => state.LoginReducer.User);
  const { follow} = useSelector((state) => state.LoginReducer);
  const dispatch= useDispatch()
  const FollowUnFollow = async (data) => {
    // const id = toast.loading( "Your UnFollowing " + datas?.DisplayName)
    let SendData = {
        MyItemAddr: data?.WalletAddress,
        ClickAddr:   localStorage.getItem('accountInfo') ,
        From: 'myitem',
        MyItemCustomUrl:data?.CustomUrl ,
        ClickCustomUrl: payload?.CustomUrl ,
    }
    // console.log('senddataaa',SendData,userProfile,payload)
    let Resp = await FollowUnFollowFunc(SendData)
    if (Resp?.success) {
        // SetFollow(Resp.msg == 'follow' ? 'unfollow' : 'follow')
        toast.success('Your UnFollowing ' + data?.DisplayName + ' Successfully')
        dispatch({
          type : "Follow",
          Follow : {
            follow : !follow
          }
        })
        // window.location.reload()
    }
    else {
        toast.error('Try Again')

    }
}
   console.log("sari sari",datas)
  return (
    <>
      <div className="inner-content follow">
        {/* <h3 className="profilename">{DisplayName}</h3> */}
        <h4 className="title-dashboard crossfitFont vert__subpageTitle" data-aos="fade-right">

          {type == 'follower' ? 'Followers' : type == 'collection' ? 'Collection' : 'Following'}
        </h4>
        <div className="content-follow">
          {/* {console.log('collectionnn',datas,type)} */}
          {datas?.list?.length==0 ? <>
                                            <div className="text-centre">
                                                <h3>Loading...</h3>
                                                {/* {/ <p>Come back soon ! or try to browse something for you on our marketplace</p> /} */}
                                                {/* <p>Nothing for ReSale at this time</p> */}
                                                        <div className="load-more">
                                                        {/* <Link to='/'><Button type="type" className="btn-load browse_marketplace" disableRipple>Browse Marketplace</Button></Link> */}
                                                        </div>
                                                </div>
                                            </> :datas?.length > 0 ?
            datas?.map(data =>
              <>
                {console.log('datttaa', data)}
                {type !== "collection" || user}
                <div className="card-author followinglist" data-aos="zoom-in-down">
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                     <BiDotsVerticalRounded className="verticaldot"/>
                    </Dropdown.Toggle>
{ user &&
                    <Dropdown.Menu>
                      <Dropdown.Item  onClick={()=>FollowUnFollow(data)} >Unfollow</Dropdown.Item>
                      {/* <Dropdown.Item>Remove</Dropdown.Item> */}
                    </Dropdown.Menu>
                     }
                  </Dropdown>
                  <div className="avatar">
                    {type == 'collection' && <img src={data?.CollectionProfileImage ? action_config.IMG_URL + "/collection/" + data?.CollectionSymbol + '/' + data?.CollectionProfileImage : config.profile} alt="images" />}
                    {(type == 'follower' || type == 'following') && <img src={data?.Profile ? action_config.IMG_URL + "/user/" + data?.WalletAddress + '/profile/' + data?.Profile : config.profile} alt="images" />}
                  </div>
                  <div className="name">
                    {" "}
                    <Link to={type == 'collection'
                      ? `/collection/${data?.CollectionSymbol}`
                      : `/profile/${data?.CustomUrl}`
                    } state={{ data }}>

                    {/* <Link to={`/profile/${data?.CustomUrl}}> */}
                      {(data?.DisplayName)}
                      </Link>
                      {" "}

                  </div>
                  {/* <div className="name1">
                  $-0.00065
                  </div> */}
                  {/* <div className="details">
              <span>{data.items}</span> Items
            </div> */}
                  {/* <Link to={type == 'collection'
              ?`/collection/${data?.CollectionSymbol}`
              :`/my-item/${data?.CustomUrl}`
              } state={{data:data}} className="btn-follow">
             {type == 'follower' ? 'Follower' :type == 'collection' ? 'Collection': 'Following'} 
            </Link> */}
                  {/* <Link to="#" className="option">
              <i className="far fa-ellipsis-h"></i>
            </Link> */}
                </div></>)
            :
            <NoData />
          }
        </div>
      </div>
    </>
  );
}
