import React from 'react'
import config from './config/config'
import { Link } from 'react-router-dom';

const BlogCard = (props) => {
    console.log(props.data,"xxxxaac");
  return (
    <>
   <Link to={`/blogdetail/${props?.data?.url}`} state={{ item: props.data }}>
      <div className='vertendi__blogCard p-4'>
        <img src={`${config.IMG_URL}/${props?.data?.img}`} alt="Card image cap" className='img-fluid blogCardImg' />

        <h5 className='vertendi__blogCardTitle firaFont mt-3'>{props?.data?.heading}</h5>
    <p className='firaFont vertendi__blogCardDesc'>{props?.data?.category}</p>

        <p className="grays">
                                Published By{" "}
                                {props?.data?.date &&
                                  `${new Date(props?.data?.date).toLocaleString(
                                    "default",
                                    { month: "long" }
                                  )} ${new Date(props?.data?.date).getDate()},${new Date(
                                    props?.data?.date
                                  ).getFullYear()}`}
                              </p>
      </div>    
    

      </Link>
    </>
  )
}

export default BlogCard
