// import ERC721 from '../../src/Abi/erc721.json'
// import ERC1155 from '../../src/Abi/erc1155.json'
// import TRADE from '../../src/Abi/market.json'
import profile from '../../assets/images/avatar.png'
var EnvName = 'demo';
var key = {};
key.KEY = 'CardBo@rD1290%6Fine3'
key.ONEDAYINSECONDS = 0
key.env=EnvName
key.ENCODEKEY = process.env.REACT_APP_ENCODEKEY
key.BLOCKS_PER_YEAR = 0
key.RPAD_ADDRESS = ''
key.ROUTER = ''
key.EMAIL           =   /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
key.MOBILE          =   /^\d{10}$/
key.NumOnly         =   /^\d+$/
key.PASSWORD        =   /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
key.OnlyAlbhabets   =   /^(?:[A-Za-z]+)(?:[A-Za-z0-9 _]*)$/
key.notSpecil       =   /^[a-zA-Z0-9]+$/
key.OnlyAlphSpecial =   /^[A-Za-z_@.#&+-]*$/
key.IPFS            =   'https://gateway.pinata.cloud/ipfs/'
// 'https://ipfs.io/ipfs/'
key.DecimalAlloweddigits = /^([0-9]+[\.]?[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?|[0-9]+)$/
key.limit = 50
key.NumDigitOnly = /[^0-9\.]/g
key.NumberOnly = /[^0-9]/g
key.COLLECTIONAME = 'VERTENDI'

if(EnvName === "demo") {
key.BASENETWORK = 'AVAX'
key.PUBLISHKEY = 'pk_test_51OFuLbSJ5IHbPpP5hvMqggW0jlWXP7Lp2mH6DQeiKSp7OFE3fVbmsjb8ftjQEOovwxLleH1I6LEvNJFyBVayIAcG00GWIor8eZ' 
key.ADMINADDRESS = '0x025c1667471685c323808647299e5DbF9d6AdcC9'.toLowerCase() 
key.ADMINCATEGORY = 'vertendi Music'
    key.FRONT_URL       =   'http://nftdemo.bimaticz.com/naifty'
    key.BACK_URL        =   'https://backend-vertendi.maticz.in/v1/front'
    key.ADMIN_URL       =   'https://backend-vertendi.maticz.in/v1/admin'
    key.IMG_URL         =   'https://backend-vertendi.maticz.in'
    key.DEADADDRESS     =   '0x000000000000000000000000000000000000dEaD'.toLowerCase()
    key.profile         =    profile
    key.TradeContract   =   '0x600A3fEed87E3a2f9EFe61eAd7F1dAe8eA60a59d'.toLowerCase()
    // key.TradeContract   =   '0x3bE52bd3A97ac6Ba20F1482e58d9694B3E15Fb05'.toLowerCase()
    key.ERC721          =   '0x9A9EBc3A48D9ddc54A2D6A3599642aBA4363E7e1'.toLowerCase()
    key.ERC1155         =    '0x4958A36d8d71abd35D5434EF78023B3284D93e63'.toLowerCase()
    key.erc20Address    =   '0xEb4fFed6B324b5FdC4d72AD3dA75bf1Fb2bB004A'.toLowerCase()
    // key.RPC_URL         =   "https://bsc-testnet.infura.io/v3/46561fa0b5cb4e26ac074bd303da720c"
    // key.RPC_URL  = "https://endpoints.omniatech.io/v1/bsc/testnet/public"
    key.RPC_URL =  "https://api.avax-test.network/ext/bc/C/rpc"
    key.chain_Id_List        =   [43113]
    key.BNBCHAIN = 43113
    key.NETWORK = 43113

    // key.ETHCHAIN = 43113
    // key.RPC_URL         =   "https://endpoints.omniatech.io/v1/bsc/testnet/public"
    key.CHAIN_ID        =   43113
    key.COIN_NAME        =   "BNB"
     key.COIN_NAMETWO        =   "BNB"
    key.Block_URL = {   
        ETH : "https://testnet.snowtrace.io/",
        BNB : "https://testnet.bscscan.com/"
    }
}

 if(EnvName === "production") {
key.ADMINCATEGORY = 'vertendi Music'

    key.ADMINADDRESS = '0x0fb556B7A5Df171d324D8cb4E42D1Ce0CAAb0C9f'.toLowerCase() 
    key.PUBLISHKEY = process.env.REACT_APP_PUB_KEY  
    key.BASENETWORK = 'POLYGON'
    key.FRONT_URL       =   'http://www.vertendi.io/'
    key.BACK_URL        =   'https://api.vertendi.io/v1/front'
    key.ADMIN_URL        =   'https://api.vertendi.io/v1/admin'
    key.IMG_URL         =   'https://api.vertendi.io'
    key.profile         =    profile
    key.DEADADDRESS     =   '0x000000000000000000000000000000000000dEaD'.toLowerCase()
    key.TradeContract   =   '0xe22d6fc8e868b716ce98df7a7afc6459b2a1a112'.toLowerCase()
    key.ERC721          =   '0xbacc6bb993477fc08ee0678ed0e1e49e074be327'.toLowerCase()
    key.ERC1155         =    '0x750ea898cb00d5f89d293369fce76e4bf6a3e4b1'.toLowerCase()
    key.erc20Address    =   '0xc2132d05d31c914a87c6611c10748aeb04b58e8f'.toLowerCase()
    key.RPC_URL         =   "https://lively-sparkling-sun.matic.quiknode.pro/a01b1989cb84596814e4523b79c649317b7d9ad0"
    key.CHAIN_ID        =   137
    key.chain_Id_List        =   [137]
    key.BNBCHAIN = 137
    key.NETWORK = 137

    key.ETHCHAIN =137
    key.COIN_NAME        =   "MATIC"
    key.Block_URL = {
        ETH : "https://polygonscan.com/",
        BNB : "https://polygonscan.com/"
    }


}

if(EnvName === "local"){
key.ADMINCATEGORY = 'vertendi Music'

    key.ADMINADDRESS = '0x025c1667471685c323808647299e5DbF9d6AdcC9'.toLowerCase() 
    key.PUBLISHKEY = 'pk_test_51OFuLbSJ5IHbPpP5hvMqggW0jlWXP7Lp2mH6DQeiKSp7OFE3fVbmsjb8ftjQEOovwxLleH1I6LEvNJFyBVayIAcG00GWIor8eZ' 
    key.BASENETWORK = 'POLYGON'
    key.FRONT_URL       =   'http://localhost:3000/naifty'
    key.BACK_URL        =   'http://localhost:3331/v1/front'
    key.ADMIN_URL        =   'http://localhost:3331/v1/admin'
    key.IMG_URL         =   'http://localhost:3331'
    key.DEADADDRESS     =   '0x000000000000000000000000000000000000dEaD'.toLowerCase()
    key.profile         =    profile
    key.TradeContract   =   '0x600A3fEed87E3a2f9EFe61eAd7F1dAe8eA60a59d'.toLowerCase()
    // key.TradeContract   =   '0x3bE52bd3A97ac6Ba20F1482e58d9694B3E15Fb05'.toLowerCase()
    key.ERC721          =   '0x9A9EBc3A48D9ddc54A2D6A3599642aBA4363E7e1'.toLowerCase()
    key.ERC1155         =    '0x4958A36d8d71abd35D5434EF78023B3284D93e63'.toLowerCase()
    key.erc20Address    =   '0xEb4fFed6B324b5FdC4d72AD3dA75bf1Fb2bB004A'.toLowerCase()
    // key.RPC_URL         =   "https://bsc-testnet.infura.io/v3/46561fa0b5cb4e26ac074bd303da720c"
    // key.RPC_URL  = "https://endpoints.omniatech.io/v1/bsc/testnet/public"
    key.RPC_URL =  "https://api.avax-test.network/ext/bc/C/rpc"
    key.chain_Id_List        =   [43113]
    key.BNBCHAIN = 43113
    key.NETWORK = 43113

    // key.ETHCHAIN = 43113
    // key.RPC_URL         =   "https://endpoints.omniatech.io/v1/bsc/testnet/public"
    key.CHAIN_ID        =   43113
    key.COIN_NAME        =   "BNB"
     key.COIN_NAMETWO        =   "BNB"
    key.Block_URL = {   
        ETH : "https://testnet.snowtrace.io/",
        BNB : "https://testnet.bscscan.com/"
    }
}

if(EnvName === "stage"){
key.ADMINCATEGORY = 'vertendi Music'

    key.BASENETWORK = 'POLYGON'
    key.PUBLISHKEY = process.env.REACT_APP_PUB_KEY 
    key.ADMINADDRESS = '0x025c1667471685c323808647299e5DbF9d6AdcC9'.toLowerCase() 
    key.FRONT_URL       =   'http://www.vertendi.io/'
    key.BACK_URL        =   'https://api.vertendi.io/v1/front'
    key.ADMIN_URL       =   'https://api.vertendi.io/v1/admin'
    key.IMG_URL         =   'https://api.vertendi.io'
    key.DEADADDRESS     =   '0x000000000000000000000000000000000000dEaD'.toLowerCase()
    key.profile         =    profile
    //demo cretencials start
    key.TradeContract   =   '0x600A3fEed87E3a2f9EFe61eAd7F1dAe8eA60a59d'.toLowerCase()
    // key.TradeContract   =   '0x3bE52bd3A97ac6Ba20F1482e58d9694B3E15Fb05'.toLowerCase()
    key.ERC721          =   '0x9A9EBc3A48D9ddc54A2D6A3599642aBA4363E7e1'.toLowerCase()
    key.ERC1155         =    '0x4958A36d8d71abd35D5434EF78023B3284D93e63'.toLowerCase()
    key.erc20Address    =   '0xEb4fFed6B324b5FdC4d72AD3dA75bf1Fb2bB004A'.toLowerCase()
    // key.RPC_URL         =   "https://bsc-testnet.infura.io/v3/46561fa0b5cb4e26ac074bd303da720c"
    // key.RPC_URL  = "https://endpoints.omniatech.io/v1/bsc/testnet/public"
    key.RPC_URL =  "https://api.avax-test.network/ext/bc/C/rpc"
    key.chain_Id_List        =   [43113]
    key.BNBCHAIN = 43113
    key.NETWORK = 43113

    // key.ETHCHAIN = 43113
    // key.RPC_URL         =   "https://endpoints.omniatech.io/v1/bsc/testnet/public"
    key.CHAIN_ID        =   43113
    key.COIN_NAME        =   "BNB"
     key.COIN_NAMETWO        =   "BNB"
    key.Block_URL = {   
        ETH : "https://testnet.snowtrace.io/",
        BNB : "https://testnet.bscscan.com/"
    }
}
export default key;
