import React, { useEffect, useRef, useState } from 'react'
import Header from '../separate/Header'
import Lottie from "lottie-react";
import Doodles from '../assets/images/vertendi/lotties/doodles.json'
import PlainPausePlay from '../assets/images/vertendi/lotties/plainPasePlay.json'
import PlainPlayer from '../assets/images/vertendi/lotties/plainPlayer.json'
import { Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { MdKeyboardArrowRight } from 'react-icons/md';
import SubscriptionCard from './SubscriptionCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import Footer from '../separate/Footer';
import { GetPlanApi, GetUserPlanApi } from '../actions/axioss/api';

const   Subscription = () => {
    const [activeTab,setAtiveTab] = useState('monthly')

const subscriptionRef = useRef()

const goNext = () => {
  if (subscriptionRef.current && subscriptionRef.current.swiper) {
    subscriptionRef.current.swiper.slideNext();
  }
};
const goPrev = () => {
  if (subscriptionRef.current && subscriptionRef.current.swiper) {
    subscriptionRef.current.swiper.slidePrev();
  }
};


const [subsCont,setSubsCont] = useState([
    {
        name : "Starter",
        features : [
            {
                img : require('../assets/images/vertendi/approve.svg').default,
                hint : "Lorem Ipsum is simply dummy",
                status : "positive"
            },
            {
                img : require('../assets/images/vertendi/reject.svg').default,
                hint : "Lorem Ipsum is simply dummy",
                status : "negative"
            },
        ],
        monthlyPrice : 20,
        annualPrice : 200        
    },
    {
        name : "Plus",
        features : [
            {
                img : require('../assets/images/vertendi/approve.svg').default,
                hint : "Lorem Ipsum is simply dummy",
                status : "positive"
            },
            {
                img : require('../assets/images/vertendi/reject.svg').default,
                hint : "Lorem Ipsum is simply dummy",
                status : "negative"
            },
            {
                img : require('../assets/images/vertendi/approve.svg').default,
                hint : "Lorem Ipsum is simply dummy",
                status : "positive"
            },
            {
                img : require('../assets/images/vertendi/reject.svg').default,
                hint : "Lorem Ipsum is simply dummy",
                status : "negative"
            },
        ],
        monthlyPrice : 50,
        annualPrice : 500        
    },
    {
        name : "Pro Plus",
        features : [
            {
                img : require('../assets/images/vertendi/approve.svg').default,
                hint : "Lorem Ipsum is simply dummy",
                status : "positive"
            },
            {
                img : require('../assets/images/vertendi/approve.svg').default,
                hint : "Lorem Ipsum is simply dummy",
                status : "positive"
            },
            {
                img : require('../assets/images/vertendi/approve.svg').default,
                hint : "Lorem Ipsum is simply dummy",
                status : "positive"
            },
            {
                img : require('../assets/images/vertendi/approve.svg').default,
                hint : "Lorem Ipsum is simply dummy",
                status : "positive"
            },
            
        ],
        monthlyPrice : 100,
        annualPrice : 1000        
    },
    {
        name : "Premium",
        features : [
            {
                img : require('../assets/images/vertendi/approve.svg').default,
                hint : "Lorem Ipsum is simply dummy",
                status : "positive"
            },
            {
                img : require('../assets/images/vertendi/reject.svg').default,
                hint : "Lorem Ipsum is simply dummy",
                status : "negative"
            },
        ],
        monthlyPrice : 500,
        annualPrice : 5000        
    },
    {
        name : "Free",
        features : [
            {
                img : require('../assets/images/vertendi/approve.svg').default,
                hint : "Lorem Ipsum is simply dummy",
                status : "positive"
            },
            {
                img : require('../assets/images/vertendi/reject.svg').default,
                hint : "Lorem Ipsum is simply dummy",
                status : "negative"
            },
        ],
        monthlyPrice : 0,
        annualPrice : 0        
    },

])

useEffect(() => {
  fetchPlans() 
},[])
const fetchPlans = async() => {
  const data = await GetPlanApi()
  console.log('planes' , data.data)
  setSubsCont(data.data)
}
  return (
    <>
      <Header/>
      <div className='vert__subscription'>
      <img src={require('../assets/images/vertendi/leftBlurBall.png')} className="vert__exploreBlurBall" />
        <img src={require('../assets/images/vertendi/rightBlurBall.png')} className="vert__exploreRightBlurBall" />
        <div className="rightDoodlesHolder">
          <Lottie animationData={Doodles} loop />
          </div>
          <div className="leftDoodlesHolder">
          <Lottie animationData={Doodles} loop />
          </div>
          <div className="explorePauseplayHolder">
            <Lottie animationData={PlainPausePlay} loop />  
          </div>
          <div className="explorePlayerHolder">
              <Lottie animationData={PlainPlayer} loop />
          </div>
          {/* <section className="tf-page-title style-2" data-aos="fade-right">
                    <div className="tf-container custom__vertContainer container">
                        <div className="row">
                            <div className="col-md-12">

                                <ul className="breadcrumbs firaFont">
                                    <li><NavLink to="/">Home <MdKeyboardArrowRight /></NavLink></li>
                                    <li>Subscription</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </section> */}
          <Container className='custom__vertContainer vert__subscriptionsContainer mt-5'>
          <h3 className="heading faq-p crossfitFont vert__subpageTitle text-center" data-aos="fade-left">Purchase a Subscription</h3>
          <p className="firaFont text-center vert__subsLightHint">Choose the plan that works for you.</p>

          {/* <div className='vert__subsTabHolder d-flex justify-content-center mt-5'>
            <div className='vert__subsTaber'>
                <button className={`vert__subsTabBtn firaFont ${activeTab === 'monthly' && 'active'}`} onClick={() => setAtiveTab('monthly')}>Monthly</button>
                <button className={`vert__subsTabBtn firaFont ${activeTab === 'yearly' && 'active'}`} onClick={() => setAtiveTab('yearly')}>Yearly <span>-20% off</span></button>
            </div>
          </div> */}

          <div className='vert__subscriptSwiperHolder pt-5'>
            <button className='vert__swiperBtnLeft' onClick={() => goPrev()}><img src={require('../assets/images/vertendi/swiperGreen.svg').default} className='img-fluid' /></button>
            <button className='vert__swiperBtnRight' onClick={() => goNext()}><img src={require('../assets/images/vertendi/swiperGreen.svg').default} className='img-fluid' /></button>
          <Swiper navigation={true} ref={subscriptionRef} modules={[Navigation]} className="mySwiper subscriptionSwiper mt-5"
        // centeredSlides={true}
              speed={500}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                450: {
                  slidesPerView: 1.3,
                  spaceBetween: 20,
                },
                550:{
                  slidesPerView: 1.4,
                  spaceBetween: 20,
                },
                650: {
                  slidesPerView: 1.6,
                  spaceBetween: 20,
                },
                750: {
                    slidesPerView: 1.8,
                    spaceBetween: 20,
                  },
                  850: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  950: {
                    slidesPerView: 2.2,
                    spaceBetween: 20,
                  },
                  1050: {
                    slidesPerView: 2.4,
                    spaceBetween: 20,
                  },
                  1150: {
                    slidesPerView: 2.6,
                    spaceBetween: 20,
                  },
                  1250: {
                    slidesPerView: 2.8,
                    spaceBetween: 20,
                  },
                  1350: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1450: {
                    slidesPerView: 3.2,
                    spaceBetween: 20,
                  },
                  1550: {
                    slidesPerView: 3.4,
                    spaceBetween: 20,
                  },
                  1650: {
                    slidesPerView: 3.6,
                    spaceBetween: 20,
                  },
                  1750: {
                    slidesPerView: 3.8,
                    spaceBetween: 20,
                  },
                  1850: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  1950: {
                    slidesPerView: 4.2,
                    spaceBetween: 20,
                  },
               
              
              }}
              >
                     {subsCont?.map((item) =>                  
                  <SwiperSlide> 
                                
          <SubscriptionCard activeTab={activeTab} data = {item} />
        
                    </SwiperSlide>
                    )}   
               
      </Swiper>
          </div>
          </Container>
      </div>
      <Footer/>
    </>
  )
}

export default Subscription
