import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {store} from './redux/store'
import { Provider } from 'react-redux';
import { Web3Provider } from "@ethersproject/providers";
import { Web3ReactProvider } from '@web3-react/core'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import  config  from './views/config/config';

function getLibrary(provider) {
  console.log("providerprovider");
  return new Web3Provider(provider);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
// config.PUBLISHKEY
const stripePromise = loadStripe(config.PUBLISHKEY);
root.render(
 
      <Provider store={store} >

<Elements stripe={stripePromise}>
        <Web3ReactProvider getLibrary={(getLibrary)}>
          <App />
        </Web3ReactProvider>
           </Elements>
      </Provider>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
